
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import { useGlobalState } from '../globalstates/GState';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import VideoFieldCtrl from '../components/formcontrols/VideoFieldCtrl';
import SmPostPreviewCtrl from '../components/formcontrols/socialpost/SmPostPreviewCtrl';


//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function IntegrationScheduledSMPost({dialogType,handlerReturnValue}) {
    const [currentUser] = useGlobalState("userInfo");
    const navigate = useNavigate();
    let params = useParams();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const totalTabs = 4;
    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});
    const [targetList, setTargetList] = useState([]);
    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.postId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('contact_detail_page', 'header_section', 'Social Media Post');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.pixelId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
        //if (helpers.permissionAllowed(currentUser.permissions, 'Reporting-TrackingPixels', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
        //}
    }

    //if ((helpers.permissionAllowed(currentUser.permissions, 'Reporting-TrackingPixels', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'Reporting-TrackingPixels', ['allowAdd']) == true && primaryKeyValue == 0)) {
        headerOptions.elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    //}
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == 0) {
            data.targets = [];
            data.status = 'Not Sent';
            //set default data
            //data.active = true;
        }        
    }

    const handleTabChange = (event, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };

    function changeTab(direction) {
        var tmpCurrentTabIndex = currentTabIndex;
        if (direction == 'next') {
            if (tmpCurrentTabIndex + 1 < totalTabs) {
                tmpCurrentTabIndex++;
            }
        } else if (direction == 'prev') {
            if (tmpCurrentTabIndex > 0) {
                tmpCurrentTabIndex--;
            }
        }
        setCurrentTabIndex(tmpCurrentTabIndex);
    }

    function onPostTypeChanged(postType) {
        var tmpFormData = { ...formData };
        tmpFormData.linkUrl = null;
        tmpFormData.imageUrl = null;
        tmpFormData.postType = postType;
        tmpFormData.targets = [];
        setFormData(tmpFormData);
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                if (data.targets != null && data.targets != '') {
                    data.targets = JSON.parse(data.targets);
                }
                console.log('formData', data);
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));

                break;
            case "SaveForm":                
                if (data.targets != null && data.targets != '') {
                    data.targets = JSON.parse(data.targets);
                }
                setPrimaryKeyValue(data.postId);
                setFormData(data);
                if (data.active == true && data.deliveryMethod == 'Instant') {
                    createSocialPost(data);
                } else {
                    var msg = 'Form has been saved.';
                    setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/integrationscheduledsmpost/' + data.postId); } })
                    setOpenDialog(true);
                }
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/integrationscheduledsmposts'); } })
                setOpenDialog(true);
                break;
            case 'CreateSocialPost':
                var msg = 'Social Post has been published.';
                setGenericDialogSchema({ title: 'Social Post Published', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/integrationscheduledsmposts'); } })
                setOpenDialog(true);
                break;
            case "GetSocialMediaTargets":
                setTargetList(data);
                break;
        }
        setShowLoader(false);
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function createSocialPost(data) {
        BAFetchData({
            callName: "CreateSocialPost",
            method: "POST",
            url: "apiintegration/createsocialpost?postid=" + data.postId,
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "integration/scheduled/sm/post/getrecord?postid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "integration_scheduled_sm_posts", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });

        BAFetchData({
            callName: "GetSocialMediaTargets",
            method: "GET",
            url: "apiintegration/getsocialmediatargets",
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });


    }, []);

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete tracking pixel. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "integration/scheduled/sm/post/delete?postid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        //if (primaryKeyValue == 0) {
        //    formData.dateCreated = new Date().toISOString();
        //} else {
        //    formData.dateModified = new Date().toISOString();
        //}
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                setShowLoader(true);
                setTimeout(() => {
                    saveForm();
                }, "500");               
            }
        }                
    }

    function saveForm() {
        var tmpFormData = { ...formData };
        tmpFormData.targets = JSON.stringify(tmpFormData.targets);

        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "integration/scheduled/sm/post/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "integration/scheduled/sm/post/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }

    function renderIcon(platform) {
        var icon = "";
        switch (platform) {
            case "Facebook":
                icon = "fa-brands fa-square-facebook fa-fw";
                break;
            case "LinkedIn":
                break;
            case "Google":
                icon = "fa-brands fa-youtube fa-fw";
                break;
            case "Instagram":
                icon = "fa-brands fa-square-instagram fa-fw";
                break;
            case "TikTok":
                icon = "fa-brands fa-tiktok fa-fw";
                break;
            case "Pinterest":
                break;
            case "YouTube":
                icon = "fa-brands fa-youtube fa-fw";
                break;
            case "Stripe":
                break;
        }
        return icon;
    }

    function getTarget(platform, id, name) {
        var index = null;
        if (formData.targets != undefined) {
            for (var i = 0; i < formData.targets.length; i++) {
                var target = formData.targets[i];
                if (target.platform == platform && target.id == id && target.name == name) {
                    index = i;
                    break;
                }
            }
        }
        return index;
    }

    function toggleTarget(platform, id, name, integrationName) {
        var tmpFormData = { ...formData };
        var index = getTarget(platform, id, name, integrationName);
        if (index == null) {
            tmpFormData.targets.push({ "platform": platform, "id": id, "name": name, "integrationName": integrationName });
        } else {
            tmpFormData.targets.splice(index, 1);
        }        
        setFormData(tmpFormData);
    }

    function renderColor(platform, id, name) {
        var color = "grey";
        var index = getTarget(platform, id, name);
        if (index != null) {
            color = "green";
        }
        return color;
    }

    function onDeliveryMethodChange(deliveryMethod) {
        if (deliveryMethod != 'Schedule') {
            setFormData(formData => ({ ...formData, scheduledDate: null }))
        }
    }

    function showTarget(integrationName, postType) {
        var show = false;
        switch (postType) {
            case "Message":
            case "Photo":
            case "Link":
                var availableList = ['Facebook'];
                if (availableList.includes(integrationName) == true) {
                    show = true;
                }
                break;
            case "Video-V-60s":
                var availableList = ['YouTube', 'Facebook'];
                if (availableList.includes(integrationName) == true) {
                    show = true;
                }
                break;
            case "Video-V-90s":
                var availableList = ['Facebook'];
                if (availableList.includes(integrationName) == true) {
                    show = true;
                }
                break;
        }
        return show;
    }

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&

                <div className="fg-app-page-box" style={{ maxWidth: "1200px", backgroundColor:"#F5F5F7" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-7" style={{backgroundColor:"white"} }>
                    <div className="row">
                        <div className="col-sm-12">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs onChange={handleTabChange}
                                    value={currentTabIndex}
                                    variant="scroll"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example">

                                    <Tab label="Step 1 -> Set Target(s)" value={0} style={{ padding: "3px 24px 3px 0px", minWidth: "60px" }} />
                                    <Tab label="Step 2 -> Post Info" value={1} style={{ padding: "3px 24px 3px 0px", minWidth: "60px" }} />
                                    <Tab label="Step 3 -> Schedule" value={2} style={{ padding: "3px 24px 3px 0px", minWidth: "60px" }} />
                                    <Tab label="Step 4 -> Activate" value={3} style={{ padding: "3px 24px 3px 0px", minWidth: "60px" }} />
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                    {currentTabIndex == 0 ?
                        <div className="row">
                                    <div className="col-sm-12">
                                        <div className="segment">
                                            <div className="segment-title">Post Type</div>
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Post Type</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={formData.postType || ''}
                                                                onChange={(e) => { onPostTypeChanged(e.target.value); }}
                                                                label="Post Type"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                <MenuItem value="Message">Text Message Only</MenuItem>
                                                                <MenuItem value="Photo">Photo</MenuItem>
                                                                <MenuItem value="Link">Link</MenuItem>
                                                                <MenuItem value="Video-V-60s">Vertical Video - Up to 60 seconds</MenuItem>
                                                                <MenuItem value="Video-V-90s">Vertical Video - 60 to 90 seconds</MenuItem>
                                                                <MenuItem value="Video-V-10m">Vertical Video - 90 sec to 10 minutes</MenuItem>
                                                            </Select>
                                                            <FormHelperText>{errorList['postType'] ? <span style={{ color: "red" }}>{errorList['postType']}</span> : ''} </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                <div className="segment">
                                    <div className="segment-title" style={{marginBottom:"0px"}}>Targets</div>
                                    <div style={{marginTop:"0px",marginBottom:"20px",fontSize:"13px"}}>Click targets to select or de-select. Selected target will turn green.</div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {targetList.map((target, index) => (
                                                    (showTarget(target.integrationName, formData.postType) == true ?
                                                    <div style={{ display: "grid", gridTemplateColumns: "35px 1fr", gridWidth: "100%", gridGap: "10px", cursor: "pointer", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "4px" }} onClick={() => { toggleTarget(target.platform, target.id, target.name, target.integrationName); }}>
                                                        <div><span className={renderIcon(target.platform)} style={{ color: renderColor(target.platform, target.id, target.name), fontSize: "32px" }}></span></div>
                                                        <div style={{paddingTop:"3px"} }>{target.name}</div>
                                                        </div>
                                                    : null)
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    {currentTabIndex == 1 ?
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="segment">
                                    <div className="segment-title">Post Info</div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                        <TextField id="title" autoFocus fullWidth label="Title" variant="standard" helperText={errorList['title'] ? <span style={{ color: "red" }}>{errorList['title']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.title || ''} InputLabelProps={{ shrink: formData.title }} onChange={(e) => setFormData(formData => ({ ...formData, title: e.target.value }))} />
                                                <TextField id="message" multiline rows={4} fullWidth label="Message" variant="standard" helperText={errorList['message'] ? <span style={{ color: "red" }}>{errorList['message']}</span> : ''} style={{ marginBottom: "16px", marginTop: "16px" }} value={formData.message || ''} InputLabelProps={{ shrink: formData.message }} onChange={(e) => setFormData(formData => ({ ...formData, message: e.target.value }))} />
                                                {formData.postType == "Link" ?
                                                    <TextField id="linkUrl" fullWidth label="Link" variant="standard" helperText={errorList['linkUrl'] ? <span style={{ color: "red" }}>{errorList['linkUrl']}</span> : ''} style={{ marginBottom: "16px", marginTop: "16px" }} value={formData.linkUrl || ''} InputLabelProps={{ shrink: formData.linkUrl }} onChange={(e) => setFormData(formData => ({ ...formData, linkUrl: e.target.value }))} />
                                                    : null}
                                                {formData.postType == "Photo" ?
                                                    <ImageFieldCtrl imgSrc={formData.imageUrl || ''} label="Image" imgType="inline" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, imageUrl: imgValue }))}></ImageFieldCtrl>
                                                    : null}
                                                {(formData.postType == "Video-V-60s" || formData.postType == "Video-V-90s" || formData.postType == "Video-V-10m") ?
                                                    <VideoFieldCtrl videoSrc={formData.videoUrl || ''} imgSrc={formData.videoThumbnail || ''} label="Image" imgType="inline" returnFullPath={true} onChange={(videoUrl, videoThumbnail) => { setFormData(formData => ({ ...formData, videoUrl: videoUrl })); setFormData(formData => ({ ...formData, videoThumbnail: videoThumbnail })); }}></VideoFieldCtrl>
                                                    : null}
                                                <TextField id="tags" multiline rows={4} fullWidth label="Tags" variant="standard" helperText={errorList['tags'] ? <span style={{ color: "red" }}>{errorList['tags']}</span> : ''} style={{ marginBottom: "16px", marginTop: "16px" }} value={formData.tags || ''} InputLabelProps={{ shrink: formData.tags }} onChange={(e) => setFormData(formData => ({ ...formData, tags: e.target.value }))} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    {currentTabIndex == 2 ?
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="segment">
                                    <div className="segment-title">Schedule</div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        defaultValue={formData.deliveryMethod}
                                                        onChange={(e) => { setFormData(formData => ({ ...formData, deliveryMethod: e.target.value })); onDeliveryMethodChange(e.target.value); }}
                                                    >
                                                        <FormControlLabel value="Schedule" control={<Radio />} label="Scheduled" />
                                                            <FormControlLabel value="Queue" control={<Radio />} label="Queue" />
                                                            <FormControlLabel value="Instant" control={<Radio />} label="Instant Delivery" />
                                                    </RadioGroup>
                                                </FormControl>
                                                {formData.deliveryMethod == "Schedule" ?
                                                    <div style={{marginTop:"16px"} }>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DesktopDateTimePicker label="Scheduled Date" variant="Standard" slotProps={{ textField: { fullWidth: true } }} defaultValue={dayjs(formData.scheduledDate).add(currentUser.currentTimezoneDTO.timezone, 'hour')} onChange={(newValue) => setFormData(formData => ({ ...formData, scheduledDate: newValue }))} />
                                                        </LocalizationProvider>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    {currentTabIndex == 3 ?
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="segment">
                                    <div className="segment-title">Activate</div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">

                                                <FormControlLabel style={{ marginBottom: "10px" }}
                                                    control={
                                                        <Switch checked={formData.active} onChange={(e) => setFormData(formData => ({ ...formData, active: e.target.checked }))} name="gilad" />
                                                    }
                                                    label="Activate this post"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}

                            <div style={{ textAlign: "right", borderTop: "5px solid #f0f0f0", paddingTop: "20px", paddingBottom:"10px" }}>
                                {currentTabIndex > 0 ?
                                    <Button variant="outlined" style={{ display: "inline-block", backgroundColor: "grey", color: "white", border: "1px solid grey" }} onClick={() => changeTab('prev')}><ArrowBackIcon />{getTranslation('contact_search_page', 'search_grid', 'Prev')}</Button>
                                    : null}
                                {currentTabIndex < (totalTabs - 1) ?
                                    <Button variant="outlined" style={{ display: "inline-block", marginLeft: "20px", backgroundColor: "grey", color: "white", border: "1px solid grey" }} onClick={() => changeTab('next')}>{getTranslation('contact_search_page', 'search_grid', 'Next')}<ArrowForwardIcon /></Button>
                                    : null}
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <SmPostPreviewCtrl formData={formData} />
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>

    );
}

export default IntegrationScheduledSMPost;
