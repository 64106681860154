import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { BAFetchData } from '../../customhooks/useBAFetch';
import { useGlobalState } from '../../globalstates/GState';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

function SectionTemplateSettings({ dialogType, buildMode,handlerReturnValue, templateData }) {

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});         
    const [templateInfo, setTemplateInfo] = useState(templateData);
    const [templateCategoryList, setTemplateCategoryList] = useState([]);
    const [currentUser] = useGlobalState("userInfo");

    function onValueChange(propertyName, value) {
        var tmpTemplateInfo = { ...templateInfo };
        tmpTemplateInfo[propertyName] = value;
        setTemplateInfo(tmpTemplateInfo);
        if (handlerReturnValue) {
            handlerReturnValue(tmpTemplateInfo);
        }
    }

    function getTemplateCategoryList() {
        var templateType = "Section-Page";
        if (buildMode == "Automation-Email") {
            templateType = "Section-Email";
        }
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "templatetype", "ConditionOperator": "EQUAL", "Value": templateType, "DataType": "String" },
                ]
            }]
        };
        BAFetchData({
            callName: "GetTemplateCategoryList",
            method: "GET",
            url: "template/category/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetTemplateCategoryList":
                setTemplateCategoryList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function getNewTemplateInfo() {
        return { templateName: '', templateCategory: '', keywords:'', override: false, systemRecord:false };
    }

    useEffect(() => {
        if (templateData == null) {
            setTemplateInfo(getNewTemplateInfo());
        } else {
            setTemplateInfo(templateData);
        }        
    }, [templateData])

    useEffect(() => {
        getTemplateCategoryList();
    }, [])

    return (
        <>
            <TextField id="templateName" autoFocus required fullWidth label="Template Name" variant="standard" style={{ marginBottom: "16px" }} onChange={(e) => { onValueChange('templateName', e.target.value.replaceAll(' ', '_').toLowerCase()); }} />
            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                <InputLabel id="demo-simple-select-standard-label">Template Category</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Template Category"
                    onChange={(e) => { onValueChange('templateCategory',e.target.value); }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {templateCategoryList.map((templateCategory, index) => (
                        <MenuItem key={"setting_template_category_" + index} value={templateCategory.categoryName}>{templateCategory.categoryName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField id="keywords" required fullWidth label="Keywords" variant="standard" style={{ marginBottom: "16px" }} onChange={(e) => { onValueChange('keywords', e.target.value.replaceAll(' ', '_').toLowerCase()); }} />
            <div>
                <FormControlLabel style={{ marginBottom: "10px" }}
                    control={
                        <Switch onChange={(e) => { onValueChange('includeDataSource', e.target.checked); }} name="gilad" />
                    }
                    label="Include Data Source if exists"
                />
            </div>
            <div>
            <FormControlLabel style={{ marginBottom: "10px" }}
                control={
                    <Switch onChange={(e) => { onValueChange('override', e.target.checked); }} name="gilad" />
                }
                label="Override if already exist"
                />
            </div>
            {(currentUser.firstName == 'System' && currentUser.lastName == 'Administrator') ?
                <div>
                <FormControlLabel style={{ marginBottom: "10px" }}
                    control={
                        <Switch onChange={(e) => { onValueChange('systemRecord', e.target.checked); }} name="gilad" />
                    }
                    label="Save as System Record"
                    />
                </div>
                : null}
        </>

    );
}

export default SectionTemplateSettings;