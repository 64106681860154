import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import TwoValueReportCtrl from '../../dashboardcontrols/TwoValueReportWCtrl';
import BroadcastStatsCtrl from './BroadcastStatsCtrl';
import RecipientListCtrl from './RecipientListCtrl';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

function BroadcastAnalyticsCtrl({ broadcastId }) {

    const [primaryKey, setPrimaryKey] = useState(null);
    const [currentUser] = useGlobalState("userInfo");
    const [formData, setFormData] = useState({});
    const [reportData, setReportData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [errorList, setErrorList] = useState({});
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (event, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };

    useEffect(() => {
        if (primaryKey != broadcastId) {
            setPrimaryKey(broadcastId);
        }
    }, [broadcastId]);

    useEffect(() => {
        setShowLoader(false);
    }, []);

    return (
        <>
            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&

                <div className="fg-app-page-box" style={{ maxWidth: "900px",borderTop:"none",padding:"0px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div style={{ fontSize: "24px", fontWeight: "500", margin: "0px" }}>Reporting</div>
                    <div className="row">
                        <div className="col-sm-12">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs onChange={handleTabChange}
                                    value={currentTabIndex}
                                    variant="scroll"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example">

                                    <Tab label="Broadcast Stats" value={0} style={{ padding: "3px 12px 3px 0px", minWidth: "60px" }} />
                                    <Tab label="Recipient List" value={1} style={{ padding: "3px 12px 3px 0px", minWidth: "60px" }} />
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                    {currentTabIndex == 0 ?
                            <BroadcastStatsCtrl broadcastId={broadcastId} />
                        : null} 
                    {currentTabIndex == 1 ?
                        <RecipientListCtrl broadcastId={broadcastId} defaultEventType="senttoserver" />
                        : null} 
                </div>
            }
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>

    );
}

export default BroadcastAnalyticsCtrl;
