import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



function ApiLoginInfoSetupCtrl({ integrationId, iSchemaId, translationData, updateId, settingsInfo, onChange }) {

    const [currentUser] = useGlobalState("userInfo");
    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));   
    const [errorList, setErrorList] = useState({});
    const [settingsInfoObj, setSettingsInfoObj] = useState(settingsInfo);

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    function onValueChange(event, propertyName, value) {
        var tmpSettingsInfo = { ...settingsInfoObj };

        tmpSettingsInfo[propertyName] = value;
        setSettingsInfoObj(tmpSettingsInfo);
        onChange(tmpSettingsInfo);
    }

    useEffect(() => {
        //getFacebookAdAccount();
    }, []);


    return (
        <>
            <div>
                <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                    <TextField fullWidth required type="password" autoComplete='off' label="API Key" variant="standard" style={{ marginBottom: "16px" }} value={settingsInfoObj.apiKey || ''} onChange={(e) => { onValueChange(e, 'apiKey', e.target.value) }} />
                    <TextField fullWidth label="User Name" variant="standard" style={{ marginBottom: "16px" }} value={settingsInfoObj.userName || ''} onChange={(e) => { onValueChange(e, 'userName', e.target.value) }} />
                    <TextField fullWidth required type="password" autoComplete='off' label="Password" variant="standard" style={{ marginBottom: "16px" }} value={settingsInfoObj.password || ''} onChange={(e) => { onValueChange(e, 'password', e.target.value) }} />
                </FormControl>
            </div>
        </>
    );
}

export default ApiLoginInfoSetupCtrl;
